body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

audio {
  /* -webkit-transition:all 0.5s linear;
  -moz-transition:all 0.5s linear;
  -o-transition:all 0.5s linear;
  transition:all 0.5s linear;
  -moz-box-shadow: 2px 2px 4px 0px #006773;
  -webkit-box-shadow:  2px 2px 4px 0px #006773;
  box-shadow: 2px 2px 4px 0px #006773;
  -moz-border-radius:7px 7px 7px 7px ;
  -webkit-border-radius:7px 7px 7px 7px ;
  border-radius: 3px 3px 3px 3px ; */
  border-radius: 3px;
  border: 1px solid #C3C3C3;
  width: 400px;
}

audio::-webkit-media-controls-panel {
  background-color: white;
}